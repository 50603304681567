import {
  HMRCInitAll,
  GOVUKInitAll,
  ClearCookies,
} from 'dvla-external-frontend';
import formUtils from '../components/js-form-utils';

require.context('../stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);
require.context('../images/', true);
require.context('dvla-external-frontend/dist/media', true);

GOVUKInitAll();
HMRCInitAll();

ClearCookies.init();

window.onload = () => {
  formUtils.registerInputListeners();
};
